import Axios from 'axios';

class InseeService {
  constructor({ config }) {
    this.axios = Axios.create({
      timeout: config.timeout || 10000,
      headers: { Authorization: `Bearer ${config.token}` },
    });
  }

  async getIndex(idbank) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.axios({
        url: `https://api.insee.fr/series/BDM/V1/data/SERIES_BDM/${idbank}`,
        method: 'GET',
      });

      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default InseeService;
