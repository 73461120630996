import Axios from 'axios';
import axiosRetry from 'axios-retry';

class AuthService {
  constructor({ config, tokenStorageService }) {
    this.axios = Axios.create({
      baseURL: config.baseUrl,
      timeout: config.timeout || 3000,
      headers: { 'Content-Type': 'application/json' },
    });
    this.tokenStorageService = tokenStorageService;

    axiosRetry(this.axios, {
      retries: 3,
      retryDelay: (retryCount) => retryCount * 1000,
      retryCondition: (e) => {
        return e.response?.status !== 401
      },
    });
  }

  async login(data) {
    const request = {
      data,
      method: 'POST',
      url: '/iam/auth',
    };

    const response = await this.axios.request(request);
    const { token, refreshToken } = response.data;
    this.tokenStorageService.saveToken(token);
    this.tokenStorageService.saveRefreshToken(refreshToken);
    return response;
  }

  async token(data) {
    const request = {
      data,
      method: 'POST',
      url: '/iam/refresh-token',
    };

    const response = await this.axios.request(request);
    const { token, refreshToken } = response.data;
    this.tokenStorageService.saveToken(token);
    this.tokenStorageService.saveRefreshToken(refreshToken);
    return response;
  }

  async passwordForgot(data) {
    const request = {
      data,
      method: 'POST',
      url: '/iam/password-forgot',
    };

    const response = await this.axios.request(request);
    return response.data;
  }

  getUserFromToken() {
    return this.tokenStorageService.getTokenPayload();
  }

  getPermissionFromToken() {
    const { permissions } = this.tokenStorageService.getTokenPayload();
    return permissions;
  }

  getScopesFromToken() {
    const { scopes } = this.tokenStorageService.getTokenPayload();
    return scopes;
  }

  isConnected() {
    const payload = this.tokenStorageService.getTokenPayload();
    if (!payload || !payload.exp || payload.exp < Date.now() / 1000) {
      const refresh = this.tokenStorageService.getRefreshPayload();
      if (!refresh || !refresh.exp || refresh.exp < Date.now() / 1000) return false;
    }
    return true;
  }

  hasPermission(neededPermission) {
    if (!this.tokenStorageService.getTokenPayload()) return false;
    const { permissions } = this.tokenStorageService.getTokenPayload();

    const result = !neededPermission
      || permissions.some((userPermission) => userPermission === 'ADMIN' || userPermission === neededPermission);

    return result;
  }

  logout() {
    this.tokenStorageService.removeToken();
  }
}

export default AuthService;
